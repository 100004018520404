import React from 'react'
import { Link } from 'gatsby'

const WorkCTA = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="shadow bb column is-two-thirds-desktop">
            <div className="has-text-centered p-4">
              Jouw onderneming beter in de kijker? <br />
              Op zoek naar een nieuwe website en expertise? <br />
              <Link to="/contact" className="button is-medium is-danger mt-4">
                Contacteer ons!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkCTA

/* Single work page */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import WorkCTA from '../components/CTA/WorkCTA'

const WorkTemplate = ({ title, content, featuredImage, url, categories }) => {
  return (
    <>
      <section className="section section-nb">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-four-fifths">
              <h1 className="is-uppercase">{title}</h1>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {categories ? (
                <div className="tag-wrap has-text-centered py-4">
                  <ul>
                    {categories.nodes.map((cat) => (
                      <Link to={`/dienst/${cat.wpParent.node.slug}/`}>
                        <li
                          className="tag-name is-inline-block py-1 px-4 mr-2 mb-2"
                          key={cat.id}
                        >
                          {cat.name}
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              ) : null}
              {url ? (
                <div className="gotoUrl has-text-centered p-4">
                  <a
                    className="button is-medium is-danger"
                    href={`https://${url}`}
                  >
                    {url}
                  </a>
                </div>
              ) : null}
              <div className="meer-link has-text-centered">
                <Link to="/werk/">MEER WERK</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="colored-back my-6">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-four-fifths is-paddingless">
              {featuredImage && (
                <GatsbyImage
                  image={featuredImage.data}
                  alt={featuredImage.alt}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <WorkCTA />
    </>
  )
}

WorkTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Work = ({ data: { werk, path, site } }) => {
  const featuredImage = {
    data: werk.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: werk.featuredImage?.node?.alt || ``,
  }

  const { title } = site.siteMetadata

  return (
    <Layout path={path}>
      <Helmet>
        <title>{`${werk.title} | Werk | ${title}`}</title>
        <meta name="description" content={werk.meta.metaDesc} />
        <meta name="og:title" content={`${werk.title} | Werk | ${title}`} />
        <meta name="og:description" content={werk.meta.metaDesc} />
      </Helmet>
      <WorkTemplate
        title={werk.title}
        content={werk.content}
        featuredImage={featuredImage}
        url={werk.url.url}
        categories={werk.categories}
      />
    </Layout>
  )
}

Work.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  title: PropTypes.string.isRequired,
}

export default Work

export const werkQuery = graphql`
  query WorkById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    werk: wpWerk(id: { eq: $id }) {
      title
      content
      categories {
        nodes {
          id
          name
          wpParent {
            node {
              slug
            }
          }
        }
      }
      url {
        url
      }
      meta {
        metaDesc
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 945, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
